import { APIError } from '@/entity/RequestManager';
import { UserBusinessPermissionFields } from '@/entity/UserBusinessPermission/UserBusinessPermission';
import UserBusinessPermissionGroup from '@/entity/UserBusinessPermissionGroup/UserBusinessPermissionGroup';
import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { SlideYUpTransition } from 'vue2-transitions';

@Component({ name: 'NewUserBusinessPermissionGroup', components: { SlideYUpTransition } })
export default class NewUserBusinessPermissionGroup extends Vue {
    $refs: {
        groupForm: ElForm,
    }

    public groupForm = {
        name: '',
        description: '',
        permissions: []
    }

    public show = false;
    public isCreating = false;
    public permissions = ['Può modificare gli ordini', 'Può creare ordini', 'Può amministrare gli account', 'Può amministrare lo store', 'Può modificare i clienti', 'Può ricevere notifiche']
    public allChecked = false;
    public adminIsIndeterminate = true;

    public rulesGroupForm = {
        name: [{ required: true, message: 'Inserire un nome', trigger: 'change' }],
        description: [{ required: false }],
        permissions: [{ required: true, message: 'Selezionare almeno un permesso', trigger: 'change' }]
    }

    public checkboxGroupChanged(permissions: string[]) {
        const length = this.groupForm.permissions.length;

        this.allChecked = length === this.permissions.length;
        this.adminIsIndeterminate = length > 0 && length < this.permissions.length;
    }

    public adminCheckClicked(val: string) {
        this.groupForm.permissions = val ? this.permissions : [];
        this.adminIsIndeterminate = false;
    }

    public confirmClose() {
        const isChanged = !_.isMatch(this.initData().groupForm, this.groupForm);

        if (isChanged) {
            this.$confirm("Ci sono modifiche non salvate, chiudere ugualmente?", "Modifiche non salvate", {
                confirmButtonText: "SÌ",
                cancelButtonText: "Annulla",
                type: "warning",
            })
                .then(() => {
                    this.closeModal();
                })
                .catch(() => {
                    return;
                });
        } else
            this.closeModal();
    }

    public async createGroup() {
        try {
            await this.$refs.groupForm.validate();
        } catch {
            return;
        }

        try {
            this.isCreating = true;

            const permissions: UserBusinessPermissionFields = { canEditOrder: false, canManageAccounts: false, canManageStore: false, canEditClient: false, canReceiveNotifications: false, canCreateOrder: false };

            this.groupForm.permissions.forEach(permission => {
                if (permission == 'Può modificare gli ordini')
                    permissions.canEditOrder = true;
                else if (permission == 'Può creare ordini')
                    permissions.canCreateOrder = true;
                else if (permission == 'Può amministrare gli account')
                    permissions.canManageAccounts = true;
                else if (permission == 'Può amministrare lo store')
                    permissions.canManageStore = true;
                else if (permission == 'Può modificare i clienti')
                    permissions.canEditClient = true;
                else if (permission == 'Può ricevere notifiche')
                    permissions.canReceiveNotifications = true;
            });

            const newGroup = await UserBusinessPermissionGroup.create({ name: this.groupForm.name, description: this.groupForm.description.nullIfEmpty(), permissions: permissions });

            this.$emit('newGroupCreated', newGroup);
            this.closeModal();
            this.$message.success({ message: 'Gruppo creato con successo', showClose: true });
        }
        catch (error) {
            if (error instanceof APIError) {
                let message = '';

                if (error.code == 10112)
                    message = 'Nome già esistente';
                else if (error.code == 10111)
                    message = 'Valore troppo lungo';
                else
                    message = `Errore codice: ${error.code}. Contattare l'assistenza`;

                this.$message.error({ message: message, showClose: true });
                return;
            }

            this.$message.error({ message: 'Errore sconosciuto. Contattare l\'assistenza', showClose: true });
        }
        finally {
            this.isCreating = false;
        }
    }

    private closeModal() {
        this.show = false;
        this.$emit('close');
    }

    public open() {
        this.$refs.groupForm.resetFields();
        Object.assign(this, this.initData());

        this.show = true;
    }

    private initData() {
        return {
            groupForm: {
                name: '',
                description: '',
                permissions: []
            }
        }
    }

    @Watch('show')
    private showChanged(val) {
        const documentClasses = document.body.classList;

        if (val)
            documentClasses.add('modal-open');
        else
            documentClasses.remove('modal-open');
    }
}

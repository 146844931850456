import { APIError } from '@/entity/RequestManager';
import Store from '@/entity/Store';
import Session from '@/Session';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({ name: 'GestioneAzienda' })
export default class GestioneAzienda extends Vue {
    public isLoading = false;
    public storeForm = {
        mail: '',
        tel: '',
        address: '',
        province: '',
        CAP: NaN,
        city: '',
        canDeliver: false,
        emailNotification: false
    }
    public storeFormRules = {
        mail: [{ required: true, validator: this.validateEmail, trigger: 'change' }],
        tel: [{ required: true, validator: this.validatePhone, trigger: 'change' }],
        address: [{ required: true, message: 'Inserire l\'indirizzo', trigger: 'change' }],
        province: [{ required: true, message: 'Inserire la provincia', trigger: 'change' }, { min: 2, message: 'Inserire una provincia valida', trigger: 'change' }, { max: 2, message: 'Inserire una provincia valida', trigger: 'change' }],
        CAP: [{ required: true, validator: this.validateCap, trigger: 'change' }],
        city: [{ required: true, message: 'Inserire la città', trigger: 'change' }, { min: 3, message: 'Inserire una città valida', trigger: 'change' }]
    };

    public get storeLogo(): string {
        return Session.instance.store.logo;
    }

    public get storeName(): string {
        return Session.instance.store.name;
    }

    public async save(): Promise<void> {
        try {
            this.isLoading = true;

            await Session.instance.updateStore(this.storeForm);

            this.$message.success({ message: 'Informazioni modificate con successo', showClose: true });
        }
        catch (error) {
            if (error instanceof APIError) {
                let message = '';

                if (error.code == 10112)
                    message = 'Email già esistente';
                else if (error.code == 10111)
                    message = 'Valore troppo lungo';
                else if (error.code == 10109)
                    message = 'Valore troppo corto';
                else
                    message = `Errore codice: ${error.code}. Contattare l'assistenza`;

                this.$message.error({ message: message, showClose: true });
                return;
            }

            this.$message.error({ message: 'Errore sconosciuto. Contattare l\'assistenza', showClose: true });
        }
        finally {
            this.isLoading = false;
        }
    }

    public async mounted(): Promise<void> {
        const store = await Store.getOne('stores')
        Session.instance.store = store;
        Object.assignProperties(this.storeForm, Session.instance.store, true);
    }

    private validateEmail(rule, value, callback): void {
        if (value === '') {
            callback(new Error('Inserire un\'email'));
        } else if (!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm.test(value)) {
            callback(new Error('Inserire un\'email valida'));
        }
        else {
            callback();
        }
    }

    private validatePhone(rule, value, callback): void {
        if (value === '') {
            callback(new Error('Inserire un numero'));
        } else if (!/^(\+39)?[ ]?([0-9]{2,4}(\/|-| )?[0-9]{5,7})$/g.test(value)) {
            callback(new Error('Inserire un numero valido'));
        } else {
            callback();
        }
    }

    private validateCap(rule, value, callback) {
        if (value === '') {
            callback(new Error('Inserire un CAP'));
        } else if (!/^\d{5}([-]|\s*)?(\d{4})?$/g.test(value)) {
            callback(new Error('Inserire un CAP valido'));
        } else {
            callback();
        }
    }
}

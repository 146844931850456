export default abstract class Helper {
    static prittifyDate(date: Date): string {
        const options: Intl.DateTimeFormatOptions = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        };

        const e = new Date(date);
        return e.toLocaleDateString('it-IT', options);
    }
}

export interface Listener {
    key: string;
    func: (arg?: any) => void;
}

export class RuntimeEventSubscriber {
    private static listeners = new Array<Listener>();

    static add(event: string, func: (arg?: any) => void) {
        this.listeners.push({ key: event, func: func });
    }

    static remove(event: string, func: (arg?: any) => void) {
        var callbackIndex = this.listeners.findIndex(item => item.key == event && item.func == func);
        if (callbackIndex > -1) this.listeners.splice(callbackIndex, 1);
    }

    static emit(event: string, arg?: any) {
        this.listeners.forEach(item => {
            if (item.key == event)
                item.func(arg);
        })
    }
}

import UserBusiness from '@/entity/UserBusiness/UserBusiness';
import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { SlideYUpTransition } from 'vue2-transitions';

@Component({ name: 'ResetUserBusinessPassword', components: { SlideYUpTransition } })
export default class ResetUserBusinessPassword extends Vue {
    $refs: {
        passwordForm: ElForm,
    }

    private userID: number;
    public show = false;
    public isLoading = false
    public fullname = '';

    public passwordForm = {
        password: '',
        repeatPassword: ''
    }
    public passwordFormRules = {
        password: [{ required: false, validator: this.validatePassword, trigger: 'change' }],
        repeatPassword: [{ required: false, validator: this.validateRepeatPassword, trigger: 'change' }]
    }

    private validatePassword(rule, value, callback) {
        if (value === '') {
            this.$refs.passwordForm.rules['password'][0].required = false;
            this.$refs.passwordForm.rules['repeatPassword'][0].required = false;
        }
        else {
            this.$refs.passwordForm.rules['password'][0].required = true;
            this.$refs.passwordForm.rules['repeatPassword'][0].required = true;

            if (this.passwordForm.repeatPassword !== '') {
                this.$refs.passwordForm.validateField('repeatPassword');
            }
        }

        callback();
    }

    private validateRepeatPassword(rule, value, callback) {
        if (value === '' && this.passwordForm.password !== '')
            callback(new Error('Reinserire la password'));
        else if (value !== this.passwordForm.password)
            callback(new Error('Le password non corrispondono'));
        else
            callback();
    }

    public open(user: UserBusiness) {
        this.$refs.passwordForm.resetFields();
        Object.assign(this, this.initData());

        this.userID = user.ID;
        this.fullname = user.fullname;

        this.show = true;
    }

    public confirmClose() {
        const isChanged = !_.isMatch({ passwordForm: this.passwordForm }, { passwordForm: this.initData().passwordForm });

        if (isChanged) {
            this.$confirm("Ci sono modifiche non salvate, chiudere ugualmente?", "Modifiche non salvate", {
                confirmButtonText: "SÌ",
                cancelButtonText: "Annulla",
                type: "warning",
            })
                .then(() => {
                    this.closeModal();
                })
                .catch(() => {
                    return;
                });
        } else
            this.closeModal();
    }

    private closeModal() {
        this.show = false;
        this.$emit('close');
    }

    private initData() {
        return {
            passwordForm: {
                password: '',
                repeatPassword: ''
            },
            userID: NaN,
            fullname: ''
        }
    }

    public async changePassword() {
        try {
            await this.$refs.passwordForm.validate();
        } catch {
            return;
        }

        this.isLoading = true;

        try {
            const psw = await UserBusiness.resetPassword(this.userID, this.passwordForm.password.nullIfEmpty());

            this.$emit('pswChanged', psw);
            this.$message.success({ message: 'Password resettata con successo', showClose: true });
            this.closeModal();
        }
        catch {
            this.$message.error({ message: 'Errore nel reset della password', showClose: true });
        } finally {
            this.isLoading = false;
        }
    }

    @Watch('show')
    private showChanged(val) {
        const documentClasses = document.body.classList;

        if (val) {
            documentClasses.add('modal-open');
        } else {
            documentClasses.remove('modal-open');
        }
    }
}

export default class UserCustomer {
    ID: string;
    firstName: string;
    lastName: string;
    email: string;
    cellPhone: string;
    fcmToken: string;
    notificationCounter: number;
    isDeleted: boolean;

    get fullname(): string {
        return `${this.firstName ?? ''} ${this.lastName ?? ''}`;
    }
}

import Store from '../Store';
import { Type } from 'class-transformer';
import RequestManager from '../RequestManager';
import UserBusinessPermissionGroup from '../UserBusinessPermissionGroup/UserBusinessPermissionGroup';
import UserBusinessPermission from '../UserBusinessPermission/UserBusinessPermission';

interface UserBusinessPost {
    firstName: string;
    lastName: string;
    mail: string;
    permissionGroupID: string;
}

export interface UserBusinessUpdate {
    firstname?: string;
    lastname?: string;
    password?: string;
}

export default class UserBusiness extends RequestManager {
    public ID: number;
    public firstName: string;
    public lastName: string;
    public mail: string;
    public password?: string;
    @Type(() => Store)
    public store: Store;
    @Type(() => UserBusinessPermissionGroup)
    public permissionGroup: UserBusinessPermissionGroup;

    public get permission(): UserBusinessPermission {
        return this.permissionGroup.permission;
    }

    public get fullname(): string {
        if (this.firstName.length >= 10) {
            return `${this.firstName[0]}. ${this.lastName}`
        } else if (this.lastName.length >= 10) {
            `${this.firstName} ${this.lastName[0]}.`;
        } else {
            return `${this.firstName} ${this.lastName}`;
        }
    }

    constructor(loginResponse?: LoginResponse) {
        super();

        if (loginResponse != null) {
            this.ID = loginResponse.ID;
            this.firstName = loginResponse.firstName;
            this.lastName = loginResponse.lastName;
            this.mail = loginResponse.mail;
            this.store = loginResponse.store;
            this.permissionGroup = loginResponse.permissionGroup;
        }
    }

    public static async create(user: UserBusinessPost): Promise<NewUserResponse> {
        return (await Object.assign(new UserBusiness(), user).postOneGetOther(NewUserResponse, 'userbusinesses'));
    }

    public static async resetPassword(ID: number, password?: string): Promise<string> {
        const pswResetRequest = new PasswordResetRequest();
        pswResetRequest.password = password;

        return (await pswResetRequest.postOneGetOther(ResetPasswordResponse, `userbusinesses/${ID}/resetpassword`)).newPassword;
    }

    public static async update(ID: number, user: UserBusinessUpdate): Promise<void> {
        await Object.assign(new UserBusiness(), user).patch(`userbusinesses/${ID}`);
    }

    public static async remove(ID: number): Promise<void> {
        await UserBusiness.delete(`userbusinesses/${ID}`);
    }
}

class PasswordResetRequest extends RequestManager {
    public password?: string;
}

export class LoginRequest extends RequestManager {
    public email: string;
    public password: string;
}

export class LoginResponse {
    public ID: number;
    public firstName: string;
    public lastName: string;
    public mail: string;
    public password?: string;
    @Type(() => Store)
    public store: Store;
    @Type(() => UserBusinessPermissionGroup)
    public permissionGroup: UserBusinessPermissionGroup;
    public token: string;
}

export class NewUserResponse {
    password: string;
    @Type(() => UserBusiness)
    user: UserBusiness;
}

export class ResetPasswordResponse {
    newPassword: string;
}

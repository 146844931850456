import { Type } from 'class-transformer';
import Order from './Order';
import RequestManager from './RequestManager';

export enum OrderStatuses {
    New,
    Pending,
    Approved,
    Rejected,
    Delivering,
    Delivered,
    Closed
}

export namespace OrderStatuses {
    export function color(status: OrderStatuses): string {
        switch (status) {
            case OrderStatuses.Pending:
                return '#e6a23c';
            case OrderStatuses.Approved:
                return '#67c23a';
            case OrderStatuses.Delivering:
                return '#67c23a';
            default:
                return '';
        }
    }

    export function toString(status: OrderStatuses): string {
        switch (status) {
            case OrderStatuses.New:
                return 'Nuovo ordine';
            case OrderStatuses.Pending:
                return 'In attesa';
            case OrderStatuses.Approved:
                return 'Accettato';
            case OrderStatuses.Rejected:
                return 'Rifiutato';
            case OrderStatuses.Delivering:
                return 'Pronto al ritiro';
            case OrderStatuses.Delivered:
                return 'Ritirato';
            case OrderStatuses.Closed:
                return 'Chiuso';
            default:
                return 'Sconosciuto'
        }
    }

    export function isRunning(status: OrderStatuses): boolean {
        switch (status) {
            case OrderStatuses.New:
                return false;
            case OrderStatuses.Pending:
                return true;
            case OrderStatuses.Approved:
                return true;
            case OrderStatuses.Rejected:
                return false;
            case OrderStatuses.Delivering:
                return false;
            case OrderStatuses.Delivered:
                return false;
            case OrderStatuses.Closed:
                return false;
            default:
                return false;
        }
    }
}

export default class OrderStatus extends RequestManager {
    public ID: number;
    public description?: string;
    @Type(() => Date)
    public createdAt: Date;
    @Type(() => Number)
    public status: OrderStatuses;
    public unread: boolean;

    public static async getStatuses(order: Order | string): Promise<OrderStatus[]> {
        let orderID = '';

        if (order instanceof Order) {
            orderID = order.ID;
        }
        else if (typeof order === 'string') {
            orderID = order;
        }

        return OrderStatus.getMany(`orders/${orderID}/statushistory`);
    }

    static async getLast(order: Order | string): Promise<OrderStatus> {
        const statuses = await this.getStatuses(order);

        return statuses[statuses.length - 1];
    }

    /**
     * 
     * @param prop1 Contiene il valore numerico
     * @param prop2 Contiene il risultato di statusToString
     * @param freeze Se true applica Object.freeze ad ogni oggetto. Default true
     */
    static getArrayObject(prop1: string, prop2: string, freeze = true): Array<Object> {
        return Object.values(OrderStatuses).map((item) => {
            if (typeof item === 'number') {
                const a = new Object();
                const value = OrderStatuses.toString(item);

                Object.defineProperty(a, prop1, { value: item, writable: false })
                Object.defineProperty(a, prop2, { value: value, writable: false })
                if (freeze)
                    Object.freeze(a);

                return a;
            }
        }).filter((item) => { return typeof item !== 'undefined' });
    }
}

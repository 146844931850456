import UserBusiness, { NewUserResponse } from '@/entity/UserBusiness/UserBusiness';
import UserBusinessPermissionGroup from '@/entity/UserBusinessPermissionGroup/UserBusinessPermissionGroup';
import Session from '@/Session';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import NewUserBusiness from '@/views/Modal/NewUserBusiness/NewUserBusiness';
import NewUserBusinessPermissionGroup from '@/views/Modal/NewUserBusinessPermissionGroup/NewUserBusinessPermissionGroup';
import ResetUserBusinessPassword from '@/views/Modal/ResetUserBusinessPassword/ResetUserBusinessPassword';
import EditUserBusiness from '@/views/Modal/EditUserBusiness/EditUserBusiness';
import EditUserBusinessPermissionGroup from '@/views/Modal/EditUserBusinessPermissionGroup/EditUserBusinessPermissionGroup';
import { APIError } from '@/entity/RequestManager';

@Component({
    name: 'GestioneUtenti', components: {
        NewUserBusiness: require('@/views/Modal/NewUserBusiness/NewUserBusiness.vue').default,
        NewUserBusinessPermissionGroup: require('@/views/Modal/NewUserBusinessPermissionGroup/NewUserBusinessPermissionGroup.vue').default,
        ResetUserBusinessPassword: require('@/views/Modal/ResetUserBusinessPassword/ResetUserBusinessPassword.vue').default,
        EditUserBusiness: require('@/views/Modal/EditUserBusiness/EditUserBusiness.vue').default,
        EditUserBusinessPermissionGroup: require('@/views/Modal/EditUserBusinessPermissionGroup/EditUserBusinessPermissionGroup.vue').default
    }
})
export default class GestioneUtenti extends Vue {
    public users = new Array<UserBusiness>();
    private groups = new Array<UserBusinessPermissionGroup>();
    private newUserBusinessModal: NewUserBusiness;
    private newUserBusinessPermissionGroupModal: NewUserBusinessPermissionGroup;
    private resetUserBusinessPasswordModal: ResetUserBusinessPassword;
    private editUserBusinessModal: EditUserBusiness;
    private editUserPermissionGroup: EditUserBusinessPermissionGroup;
    public isUsersLoading = false;
    public isGroupsLoading = false;

    public get computedGroups() {
        return this.groups.map(group => {
            return {
                ID: group.ID,
                name: group.name,
                description: group.description,
                canEditClient: group.permission.canEditClient ? 'Sì' : 'No',
                canEditOrder: group.permission.canEditOrder ? 'Sì' : 'No',
                canCreateOrder: group.permission.canCreateOrder ? 'Sì' : 'No',
                canManageAccounts: group.permission.canManageAccounts ? 'Sì' : 'No',
                canManageStore: group.permission.canManageStore ? 'Sì' : 'No',
                canReceiveNotifications: group.permission.canReceiveNotifications ? 'Sì' : 'No'
            }
        });
    }

    async mounted() {
        this.newUserBusinessModal = <NewUserBusiness>this.$refs.newUserBusiness;
        this.newUserBusinessPermissionGroupModal = <NewUserBusinessPermissionGroup>this.$refs.newUserBusinessPermissionGroup;
        this.resetUserBusinessPasswordModal = <ResetUserBusinessPassword>this.$refs.resetUserBusinessPassword;
        this.editUserBusinessModal = <EditUserBusiness>this.$refs.editUserBusiness;
        this.editUserPermissionGroup = <EditUserBusinessPermissionGroup>this.$refs.editUserBusinessPermissionGroup;

        await this.loadGroups();
        await this.loadUsers();
    }

    public async newUserCreated(response: NewUserResponse) {
        this.users.push(response.user);

        await this.$alert(`La password per ${response.user.fullname} è: ${response.password}`, "Password", {
            type: "success",
            confirmButtonText: "OK",
            cancelButtonText: "Copia password",
            showCancelButton: true,
            callback: async action => {
                if (action == "cancel") {
                    try {
                        await navigator.clipboard.writeText(response.password);

                        this.$message.success({ message: "Password copiata negli appunti", showClose: true });
                    } catch {
                        this.$message.error({ message: "Impossibile copiare la password negli appunti", showClose: true });
                    }
                }
            }
        });
    }

    public newGroupCreated(group: UserBusinessPermissionGroup) {
        this.groups.push(group);
    }

    public addUser() {
        this.newUserBusinessModal.open();
    }

    public editUser(user: UserBusiness) {
        this.editUserBusinessModal.open(user);
    }

    public userUpdated(user: any) {
        const userFind = this.users.find(userArray => { return userArray.ID == user.ID });

        if (userFind != undefined) {
            userFind.firstName = user.firstname;
            userFind.lastName = user.lastname;
        }
    }

    public editPsw(row: UserBusiness) {
        this.resetUserBusinessPasswordModal.open(row);
    }

    public async pswChanged(newPassword: string) {
        await this.$alert(`La password è: ${newPassword}`, "Password", {
            type: "success",
            confirmButtonText: "OK",
            cancelButtonText: "Copia password",
            showCancelButton: true,
            callback: async action => {
                if (action == "cancel") {
                    try {
                        await navigator.clipboard.writeText(newPassword);

                        this.$message.success({ message: "Password copiata negli appunti", showClose: true });
                    } catch {
                        this.$message.error({ message: "Impossibile copiare la password negli appunti", showClose: true });
                    }
                }
            }
        });
    }

    public addGroup() {
        this.newUserBusinessPermissionGroupModal.open();
    }

    public editGroup(group: UserBusinessPermissionGroup) {
        this.editUserPermissionGroup.open(group);
    }

    public groupEdited(group: any) {
        const groupFind = this.groups.find(groupArray => { return groupArray.ID == group.ID });

        if (groupFind != undefined) {
            groupFind.name = group.name;
            groupFind.description = group.description;
            groupFind.permission = group.permissions;
        }

        this.users.forEach(user => {
            if (user.permissionGroup.ID == group.ID) {
                user.permissionGroup = groupFind;
            }
        });
    }

    private async loadUsers() {
        this.isUsersLoading = true;

        this.users = await Session.instance.userBusiness.store.getUserbusinesses();

        this.isUsersLoading = false;
    }

    private async loadGroups() {
        this.isGroupsLoading = true;

        this.groups = await Session.instance.userBusiness.store.getPermissiongroups();

        this.isGroupsLoading = false;
    }

    public async removeGroup(ID: string) {
        try {
            await UserBusinessPermissionGroup.remove(ID);

            this.groups.delete(group => { return group.ID == ID });

            this.$message.success({ message: "Gruppo rimosso con successo", showClose: true });
        }
        catch (error) {
            console.log(error);
            if (error instanceof APIError) {
                let message = '';

                if (error.code == 10208)
                    message = 'Il gruppo contiene degli utenti';
                else
                    message = `Errore codice: ${error.code}. Contattare l'assistenza`;

                this.$message.error({ message: message, showClose: true });
                return;
            }

            this.$message.error({ message: 'Errore sconosciuto. Contattare l\'assistenza', showClose: true });
        }
    }

    public async removeUser(ID: number) {
        try {
            await UserBusiness.remove(ID);

            this.users.delete(user => { return user.ID == ID });

            this.$message.success({ message: "Utente rimosso con successo", showClose: true });
        }
        catch {
            this.$message.error({ message: 'Errore sconosciuto. Contattare l\'assistenza', showClose: true });
        }
    }
}

import NProgress from 'nprogress';
const progressShowDelay = 50;
let routeResolved = false;

/**
 * Initializes NProgress after a specified delay only if the route was not resolved yet.
 */

export default class progress {
  static _router;

  static tryInitProgress() {
    routeResolved = false;
    setTimeout(() => {
      if (!routeResolved) {
        NProgress.start();
      }
    }, progressShowDelay);
  }

  static stopProgress() {
    routeResolved = true;
    NProgress.done();
  }

  static initProgress(router) {
    this._router = router;

    router.beforeEach((to, from, next) => {
      this.tryInitProgress();
      return next();
    });

    this._router.afterEach(() => {
      // Complete the animation of the route progress bar.
      this.stopProgress();
    });
  }
}

import RequestManager from './RequestManager';
import { OrderStatuses } from './OrderStatus';
import Order from './Order';
import { Type, plainToInstance } from 'class-transformer';

export interface ISearchOrdersParameters {
    from?: number,
    to?: number,
    find?: string,
    month?: number,
    year?: number,
    status?: OrderStatuses[]
}

export default class OrderStore extends RequestManager {
    public firstName: string;
    public lastName: string;
    public customerID: string;
    public orderID: string;
    @Type(() => Date)
    public dueDate: Date;
    @Type(() => Date)
    public createdAt: Date;
    @Type(() => Number)
    public status: OrderStatuses;
    public statusDescription?: string;
    @Type(() => Date)
    public statusDate: Date;

    public get fullname(): string {
        return this.firstName + ' ' + this.lastName;
    }

    constructor(order?: Order) {
        super();

        if (order != null) {
            this.firstName = order.userCustomer.firstName;
            this.lastName = order.userCustomer.lastName;
            this.customerID = order.userCustomer.ID;
            this.orderID = order.ID;
            this.dueDate = order.dueDate;
            this.createdAt = order.createdAt;
            this.status = order.lastStatus.status;
            this.statusDescription = order.lastStatus.description;
            this.statusDate = order.lastStatus.createdAt;
        }
    }

    public static fromOrder(order: Order): OrderStore {
        let raw = {
            createdAt: order.createdAt,
            dueDate: order.dueDate,
            firstName: order.userCustomer.firstName,
            lastName: order.userCustomer.lastName,
            customerID: order.userCustomer.ID,
            orderID: order.ID,
            status: order.lastStatus.status,
            statusDate: order.lastStatus.createdAt,
            statusDescription: order.lastStatus.description
        };

        return plainToInstance(OrderStore, raw);
    }

    public static async getOrders(query: ISearchOrdersParameters): Promise<OrderStore[]> {
        if (query.status && Array.isArray(query.status)) {
            query.status = <any>query.status.join();
        }

        return await OrderStore.getMany('stores/orders', { params: query });
    }

    public static async getOrder(id: string) {
        return await OrderStore.getOne(`stores/orders/${id}`)
    }

    public async getDetails(): Promise<Order> {
        return await Order.getOne(`orders/${this.orderID}`);
    }
}

<template>
  <div class="user">
    <div class="photo">
      <img :src="getLogoURL" alt="user avatar" />
    </div>
    <div class="info">
      <a style="color: white">
        <span>
          {{ getFullName }}
        </span>
      </a>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import Session from "../../../Session";

export default {
  components: {
    CollapseTransition,
  },
  data() {
    return {};
  },
  computed: {
    getFullName() {
      return Session.instance.userBusiness.fullname;
    },
    getLogoURL() {
      return Session.instance.userBusiness.store.logo;
    },
  },
  mounted: function () {
    Session.instance.onUserChange.on(() => {
      this.$forceCompute('getFullName');
    });
  },
};
</script>
<style scoped>
.nav.nav-menu {
  margin-top: 0;
}
</style>

import { Type } from 'class-transformer';
import RequestManager from './RequestManager';
import UserBusiness from './UserBusiness/UserBusiness';
import UserBusinessPermissionGroup from './UserBusinessPermissionGroup/UserBusinessPermissionGroup';
import UserCustomerStore from './UserCustomer/UserCustomerStore';

export class OrderNotification extends RequestManager {
    public ID: string;
    @Type(() => Date)
    public createdAt: Date;
}

export default class Store extends RequestManager {
   public ID: string;
   public name: string;
   public logo: string;
   public address: string;
   public CAP: number;
   public province: string;
   public city: string;
   public mail: string;
   public tel?: string;
   public emailNotification?: boolean;
   public notificationCount: number;
   public canDeliver: boolean;

    public async getNotifications(): Promise<OrderNotification[]> {
        return OrderNotification.getMany('stores/notifications');
    }

    public async getUserbusinesses(): Promise<UserBusiness[]> {
        return UserBusiness.getMany(`stores/userbusinesses`);
    }

    public async getPermissiongroups(): Promise<UserBusinessPermissionGroup[]> {
        return await UserBusinessPermissionGroup.getMany(`stores/permissiongroups`);
    }

    public async update(store: UpdateStore): Promise<void> {
        await Object.assign(new Store(), store).patch('stores');
    }

    public async getUserCustomers(fullname?: string): Promise<UserCustomerStore[]> {
        if (fullname == null) {
            return await UserCustomerStore.getMany('stores/usercustomers');
        }
        else {
            return await UserCustomerStore.getMany('stores/usercustomers', { params: { fullname: fullname } });
        }
    }
}

export interface UpdateStore {
    name?: string;
    logo?: string;
    address?: string;
    CAP?: number;
    province?: string;
    city?: string;
    mail?: string;
    tel?: string;
    emailNotification?: boolean;
    canDeliver?: boolean;
}

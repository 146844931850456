<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="copyright pull-right">
        &copy; {{ year }} &bull; Coded &amp; designed by
        <a href="https://www.solutiontech.tech" target="_blank" rel="noopener">Solution Tech</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      name: "main-footer",
      year: new Date().getFullYear()
    }  
  }
};
</script>
<style scoped>
  .copyright.pull-right a{
    color: currentColor;
  }
</style>

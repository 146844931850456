import { APIError } from '@/entity/RequestManager';
import UserBusiness from '@/entity/UserBusiness/UserBusiness';
import UserBusinessPermissionGroup from '@/entity/UserBusinessPermissionGroup/UserBusinessPermissionGroup';
import Session from '@/Session';
import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { SlideYUpTransition } from 'vue2-transitions';

@Component({ name: 'NewUserBusiness', components: { SlideYUpTransition } })
export default class NewUserBusiness extends Vue {
    $refs: {
        userForm: ElForm
    }

    private groups = new Array<UserBusinessPermissionGroup>();
    public show = false;
    public isCreating = false;

    public userForm = {
        firstname: '',
        lastname: '',
        email: '',
        permissionGroup: {
            selected: '',
            groups: [],
            isLoading: false,
        }
    }

    public rulesUserForm = {
        firstname: [{ required: true, message: 'Inserire un nome', trigger: 'change' }, { min: 3, message: 'Nome troppo corto', trigger: 'change' }],
        lastname: [{ required: true, message: 'Inserire un cognome', trigger: 'change' }, { min: 3, message: 'Cognome troppo corto', trigger: 'change' }],
        email: [{ required: true, validator: this.validateEmail, trigger: 'change' }],
        'permissionGroup.selected': [{ required: true, message: 'Selezionare un gruppo', trigger: 'change' }]
    }

    private async loadGroups() {
        this.userForm.permissionGroup.isLoading = true;

        this.groups = await Session.instance.userBusiness.store.getPermissiongroups();
        this.userForm.permissionGroup.groups = this.groups.map(group => { return { value: group.ID, label: group.name } });

        this.userForm.permissionGroup.isLoading = false;
    }

    public async createUser() {
        try {
            await this.$refs.userForm.validate()
        } catch {
            return;
        }

        try {
            this.isCreating = true;

            const response = await UserBusiness.create({ firstName: this.userForm.firstname, lastName: this.userForm.lastname, mail: this.userForm.email, permissionGroupID: this.userForm.permissionGroup.selected })

            this.$emit('newUserCreated', response);
            this.closeModal();
            this.$message.success({ message: 'Utente creato con successo', showClose: true });
        }
        catch (error) {
            if (error instanceof APIError) {
                let message = '';

                if (error.code == 10112)
                    message = 'Email già esistente';
                else if (error.code == 10111)
                    message = 'Valore troppo lungo';
                else if (error.code == 10109)
                    message = 'Valore troppo corto';
                else
                    message = `Errore codice: ${error.code}. Contattare l'assistenza`;

                this.$message.error({ message: message, showClose: true });
                return;
            }

            this.$message.error({ message: 'Errore sconosciuto. Contattare l\'assistenza', showClose: true });
        }

        this.isCreating = false;
    }

    public async open() {
        this.$refs.userForm.resetFields();
        Object.assign(this, this.initData());

        this.show = true;

        await this.loadGroups();
    }

    private closeModal() {
        this.show = false;
        this.$emit('close');
    }

    public confirmClose() {
        const isChanged = !_.isMatch(
            {
                firstname: this.userForm.firstname, lastname: this.userForm.lastname,
                email: this.userForm.email, permissionGroupID: this.userForm.permissionGroup.selected
            }, {
            firstname: this.initData().userForm.firstname, lastname: this.initData().userForm.lastname,
            email: this.initData().userForm.email, permissionGroupID: this.initData().userForm.permissionGroup.selected
        });

        if (isChanged) {
            this.$confirm("Ci sono modifiche non salvate, chiudere ugualmente?", "Modifiche non salvate", {
                confirmButtonText: "SÌ",
                cancelButtonText: "Annulla",
                type: "warning",
            })
                .then(() => {
                    this.closeModal();
                })
                .catch(() => {
                    return;
                });
        } else
            this.closeModal();
    }

    private initData() {
        return {
            userForm: {
                firstname: '',
                lastname: '',
                email: '',
                permissionGroup: {
                    selected: '',
                    groups: [],
                    isLoading: false,
                }
            }
        }
    }

    private validateEmail(rule, value, callback) {
        if (value === '') {
            callback(new Error('Inserire un\'email'));
        } else if (!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm.test(value)) {
            callback(new Error('Inserire un\'email valida'));
        } else {
            callback();
        }
    }

    @Watch('show')
    private showChanged(val) {
        const documentClasses = document.body.classList;

        if (val) {
            documentClasses.add('modal-open');
        } else {
            documentClasses.remove('modal-open');
        }
    }
}

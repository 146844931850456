import RequestManager from './RequestManager';
import ProductSize from "./ProductSize";
import { Type } from 'class-transformer';
import Producer from './Producer/Producer';

export default class Product extends RequestManager {
    public ID: number;
    public commercialName: string;
    public description: string;
    public logo?: string;
    @Type(() => Producer)
    public producer: Producer;
    @Type(() => ProductSize)
    public productSizes: ProductSize[];

    public get producername(): string {
        return this.producer?.name ?? '';
    }
}

import RequestManager from './RequestManager';

export default class OrderStats extends RequestManager {
    public newOrders: number;
    public expiredOrders: number;
    public expiringOrders: number;
    public totalActiveOrders: number;

    public static async getStats() {
        return await OrderStats.getOne('stores/orders/stats');
    }
}

import { APIError } from '@/entity/RequestManager';
import UserBusiness from '@/entity/UserBusiness/UserBusiness';
import Session from '@/Session';
import { TypedEvent } from '@/TypedEvent';
import { ElForm } from 'element-ui/types/form';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { SlideYUpTransition } from 'vue2-transitions';

@Component({ name: 'EditCurrentUser', components: { SlideYUpTransition } })
export default class EditCurrentUser extends Vue {
    $refs: {
        userForm: ElForm
    }

    public show = false;
    public isLoading = false;
    private hadOpenClass = false;

    public userForm = {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        repeatPassword: ''
    }

    public userFormRules = {
        firstname: [{ required: true, message: 'Inserire il nome', trigger: 'change' }, { min: 3, message: 'Nome troppo corto', trigger: 'change' }],
        lastname: [{ required: true, message: 'Inserire il cognome', trigger: 'change' }, { min: 3, message: 'Cognome troppo corto', trigger: 'change' }],
        email: [{ required: true, validator: this.validateEmail, trigger: 'change' }],
        password: [{ required: true, validator: this.validatePassword, trigger: 'change' }],
        repeatPassword: [{ required: true, validator: this.validateRepeatPassword, trigger: 'change' }]
    }

    public open() {
        this.$refs.userForm.resetFields();

        const user = Session.instance.userBusiness;

        this.userForm.firstname = user.firstName;
        this.userForm.lastname = user.lastName;
        this.userForm.email = user.mail;
        this.userForm.password = '';
        this.userForm.repeatPassword = '';

        this.show = true;
    }

    public async updateUser() {
        try {
            await this.$refs.userForm.validate();
        } catch {
            return;
        }

        try {
            const sessionUser = Session.instance.userBusiness;

            if (this.userForm.firstname === sessionUser.firstName &&
                this.userForm.lastname === sessionUser.lastName &&
                (this.userForm.password === '' && this.userForm.repeatPassword === '')) {
                this.closeModal();

                return;
            }

            this.isLoading = true;

            const user = { firstname: this.userForm.firstname, lastname: this.userForm.lastname, password: this.userForm.password };

            await Session.instance.updateUser(user);

            this.$message.success({ message: 'Utente modificato con successo', showClose: true });
        }
        catch (error) {
            if (error instanceof APIError) {
                let message = '';

                if (error.code == 10112)
                    message = 'Email già esistente';
                else if (error.code == 10111)
                    message = 'Nome o cognome troppo lunghi';
                else if (error.code == 10109)
                    message = 'Nome o cognome troppo corti';
                else
                    message = `Errore codice: ${error.code}. Contattare l'assistenza`;

                this.$message.error({ message: message, showClose: true });
                return;
            }

            this.$message.error({ message: 'Errore sconosciuto. Contattare l\'assistenza', showClose: true });
        }
        finally {
            this.isLoading = false;
        }
    }

    public confirmClose() {
        this.closeModal()
    }

    private closeModal() {
        this.show = false;
        this.$emit('close');
    }

    @Watch('show')
    private showChanged(val) {
        const documentClasses = document.body.classList;

        if (val && !this.hadOpenClass) {
            documentClasses.add('modal-open');
        } else if (!val && !this.hadOpenClass) {
            documentClasses.remove('modal-open');
        }
    }

    private validatePassword(rule, value, callback) {
        if (value === '' && this.userForm.repeatPassword === '') {
            callback();

            return;
        } else if (value === '') {
            callback(new Error('Inserire una password'));

            return;
        } else {
            if (this.userForm.repeatPassword !== '') {
                this.$refs.userForm.validateField('repeatPassword');

                callback();
            }

            return;
        }
    }

    private validateRepeatPassword(rule, value, callback) {
        if (value === '' && this.userForm.password === '') {
            callback();
            return;

        } else if (value === '') {
            callback(new Error('Reinserire una password'));
        } else if (value !== this.userForm.password) {
            callback(new Error('Le password non corrispondono'));
        } else {
            callback();
        }
    }

    private validateEmail(rule, value, callback) {
        if (value === '') {
            callback(new Error('Inserire un\'email'));
        } else if (!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm.test(value)) {
            callback(new Error('Inserire un\'email valida'));
        } else {
            callback();
        }
    }
}

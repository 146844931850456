<template>
  <div
    class="form-group no-border"
    :class="[
       {'input-group': hasIcon},
       {'input-group-focus': focused},
       {'has-label': label || $slots.label}]"
  >
    <slot name="label">
      <label v-if="label" :class="labelClasses">{{label}}</label>
    </slot>

    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
      <span class="input-group-text">
        <slot name="addonLeft">
          <i :class="addonLeftIcon"></i>
        </slot>
      </span>
    </div>
    <slot>
      <input
        :value="value"
        v-on="listeners"
        v-bind="$attrs"
        class="form-control"
        aria-describedby="addon-right addon-left"
      />
    </slot>
    <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
      <span class="input-group-text">
        <slot name="addonRight">
          <i :class="addonRightIcon"></i>
        </slot>
      </span>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div class="invalid-feedback error-text" :class="{'mt-2': hasIcon}" v-if="error">{{ error }}</div>
    </slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "lg-input",
  props: {
    label: {
      type: String,
      description: "Input label (text before input)"
    },
    error: {
      type: String,
      description: "Input error (below input)"
    },
    labelClasses: {
      type: String,
      description: "Input label css classes"
    },
    inputClasses: {
      type: String,
      description: "Input css classes"
    },
    value: {
      type: [String, Number],
      description: "Input value"
    },
    addonRightIcon: {
      type: String,
      description: "Addon right icon"
    },
    addonLeftIcon: {
      type: String,
      description: "Addont left icon"
    }
  },
  data() {
    return {
      touched: false,
      focused: false,
      hadError: false
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      };
    },

    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    }
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      if (!this.touched && value) {
        this.touched = true;
      }
      this.$emit("input", value);
    },
    onFocus(value) {
      this.focused = true;
      this.$emit("focus", value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit("blur", value);
    }
  },
  created() {
    this.$watch(
      "error",
      newVal => {
        if (newVal) {
          this.hadError = true;
        }
      },
      { immediate: true }
    );
  }
};
</script>
<style scoped lang = "scss">
.error-text {
  display: block;
  font-size: 15px;
}

.input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

i {
  color: #ffffff;
}

.form-control::placeholder {
  color: #ffffff;
}

.form-control:focus {
  color: #ffffff;
}
.form-group.no-border.input-group .form-control {
  color: #ffffff;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
</style>

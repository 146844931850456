import Item from './Item';
import RequestManager from './RequestManager';
import { Type } from 'class-transformer';
import OrderStatus from './OrderStatus';
import UserCustomer from './UserCustomer/UserCustomer';
import UserBusinessOrder from './UserBusinessOrder/UserBusinessOrder';
import Session from '@/Session';
import Product from './Product';
import ProductSize from './ProductSize';
import _ from 'lodash';

interface IOrderProduct {
    sizeId: string;
    quantity: number;
}

export default class Order extends RequestManager {
    public ID: string;
    @Type(() => Date)
    public dueDate: Date;
    @Type(() => Date)
    public createdAt: Date;
    @Type(() => Item)
    public items?: Item[];
    @Type(() => UserCustomer)
    public userCustomer!: UserCustomer;
    @Type(() => OrderStatus)
    public statuses: OrderStatus[];
    @Type(() => UserBusinessOrder)
    public userBusinessOrder?: UserBusinessOrder;
    public isFromUserCustomer: boolean;
    public notes?: string;
    public address?: string;

    public get lastStatus(): OrderStatus | null {
        if (this.statuses == null || this.statuses.length === 0) {
            return null;
        }

        return this.statuses[this.statuses.length - 1];
    }

    public set lastStatus(newStatus: OrderStatus) {
        this.statuses.push(newStatus);
    }

    public static async get(ID: string): Promise<Order> {
        return await Order.getOne(`orders/${ID}`);
    }

    public static async getPDF(ID: string): Promise<{ blob: Blob, filename: string }> {
        const res = await Order.getRaw(`orders/${ID}/pdf`, { responseType: 'blob' });

        return { blob: new Blob([res.data], { type: 'application/pdf' }), filename: res.headers['content-disposition'].replace('filename=', '') };
    }
}

export class NewOrder extends Order {
    public edit = false;
    public loading = false;
    public saving = false;
    public editProduct = {
        selected: '',
        producer: '',
        products: Array<any>(),
        options: Array<any>(),
        loading: false,
    };
    public editProductSize = {
        selected: '',
        options: Array<any>(),
        enabled: false,
    };
    public editResult = {
        product: Product,
        size: ProductSize,
        quantity: Number,
    };
    public newItems = new Array<Item>();

    public constructor(order?: Order) {
        super();

        if (order != null) {
            Object.assign(this, _.cloneDeep(order));
            this.newItems = this.items;
        } else {
            this.dueDate = new Date(new Date().setDate(new Date().getDate() + 1));
            this.userCustomer = new UserCustomer();
            this.items = new Array<Item>();
        }
    }

    public async updateItems() {
        const patchOrder = new Order();
        patchOrder.items = <any>this.newItems.map((item) => {
            const newItem: IOrderProduct = {
                sizeId: item.size.ID,
                quantity: item.quantity
            };
            return newItem;
        });

        await patchOrder.put(`orders/${this.ID}`);
    }

    public async createOrder() {
        const newOrder = {
            items: this.newItems.map((item) => {
                return { sizeId: item.size.ID, quantity: item.quantity };
            }),
            dueDate: this.dueDate,
            userCustomerId: this.userCustomer.ID,
            storeID: Session.instance.storeID,
            notes: this.notes,
            address: this.address,
        };

        // await this.postOne('order'); //Da provare
        await RequestManager.postGenericOne(newOrder, 'orders');
    }
}

import { Type } from 'class-transformer';
import RequestManager from '../RequestManager';
import UserCustomer from './UserCustomer';

export default class UserCustomerStore extends RequestManager {
    @Type(() => Date)
    createdAt: Date;
    @Type(() => UserCustomer)
    userCustomer: UserCustomer;
}

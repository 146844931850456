import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { plainToClass, plainToClassFromExist } from 'class-transformer';
import _ from 'lodash';

export interface IAPIError {
    httpCode: number;
    code: number;
    message: string;
}

export class APIError extends Error implements IAPIError {
    httpCode: number;
    code: number;
    message: string;

    constructor(error?: IAPIError) {
        super(error.message);
        Object.setPrototypeOf(this, APIError.prototype);

        this.name = 'APIError';
        this.httpCode = error.httpCode;
        this.code = error.code;
    }
}

function isAxiosError<T>(error: AxiosError | any): error is AxiosError<T> {
    return error && error.isAxiosError
}

function instanceOfIAPIError(object: any): object is IAPIError {
    return 'code' in object && 'message' in object; //'httpCode' in object &&
}

export default abstract class RequestManager {
    private static readonly baseURL: string = process.env.API_URL;

    static async getRaw(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return await axios.get(RequestManager.baseURL + url, config);
    }

    static async getMany<T>(this: (new () => T), url: string, config?: AxiosRequestConfig): Promise<T[]> {
        const obj = new Array<T>();

        try {
            const res = await axios.get(RequestManager.baseURL + url, config);

            if (Object.isEmpty(res?.data)) {
                return obj;
            }

            if (Array.isArray(res.data)) {
                res.data.forEach((d: T) => {
                    obj.push(plainToClass(this, d));
                });
            } else {
                obj.push(plainToClass(this, res.data));
            }
        } catch (error) {
            if (isAxiosError(error) && error.response) {
                if (instanceOfIAPIError(error.response.data))
                    throw new APIError(<any>error.response.data);
            }

            throw error;
        }

        return obj;
    }

    static async getOne<T>(this: (new () => T), url: string, config?: AxiosRequestConfig): Promise<T | null> {
        try {
            const res = await axios.get(RequestManager.baseURL + url, config);

            return plainToClass(this, res?.data) ?? null;
        } catch (error) {
            if (isAxiosError(error) && error.response) {
                if (instanceOfIAPIError(error.response.data))
                    throw new APIError(<any>error.response.data);
            }

            throw error;
        }
    }

    static async postGenericOne(o: Object, url: string, config?: AxiosRequestConfig): Promise<void> {
        try {
            await axios.post(RequestManager.baseURL + url, o, config);
        } catch (error) {
            if (isAxiosError(error) && error.response) {
                if (instanceOfIAPIError(error.response.data))
                    throw new APIError(<any>error.response.data);
            }

            throw error;
        }
    }

    async postOne<T>(this: T, url: string, config?: AxiosRequestConfig): Promise<T | null> {
        let obj: T;

        try {
            const clonedThis = _.cloneDeep(this);
            const res = await axios.post(RequestManager.baseURL + url, this, config)

            if (!Object.isEmpty(res.data))
                obj = plainToClassFromExist(clonedThis, res.data);
            else
                obj = null;
        } catch (error) {
            if (isAxiosError(error) && error.response) {
                if (instanceOfIAPIError(error.response.data))
                    throw new APIError(<any>error.response.data);
            }

            throw error;
        }

        return obj;
    }

    async postOneGetOther<T, K>(this: T, to: (new () => K), url: string, config?: AxiosRequestConfig): Promise<K> {
        try {
            const res = await axios.post(RequestManager.baseURL + url, this, config)

            return plainToClass(to, res.data);
        } catch (error) {
            if (isAxiosError(error) && error.response) {
                if (instanceOfIAPIError(error.response.data))
                    throw new APIError(<any>error.response.data);
            }

            throw error;
        }
    }

    async patch<T>(this: T, url: string, config?: AxiosRequestConfig) {
        try {
            await axios.patch(RequestManager.baseURL + url, this, config)
        } catch (error) {
            if (isAxiosError(error) && error.response) {
                if (instanceOfIAPIError(error.response.data))
                    throw new APIError(<any>error.response.data);
            }

            throw error;
        }
    }

    async put<T>(this: T, url: string, config?: AxiosRequestConfig) {
        try {
            await axios.put(RequestManager.baseURL + url, this, config)
        } catch (error) {
            if (isAxiosError(error) && error.response && instanceOfIAPIError(error.response.data)) {
                throw new APIError(<any>error.response.data);
            }

            throw error;
        }
    }

    static async post<T>(this: (new () => T), url: string, config?: AxiosRequestConfig): Promise<T> {
        try {
            const res = await axios.post(RequestManager.baseURL + url, this, config)

            return plainToClass(this, res.data);
        } catch (error) {
            if (isAxiosError(error) && error.response) {
                if (instanceOfIAPIError(error.response.data))
                    throw new APIError(<any>error.response.data);
            }

            throw error;
        }
    }

    static async postOneGetOther<T, K>(this: (new () => T), to: (new () => K), url: string, config?: AxiosRequestConfig): Promise<K> {
        try {
            const res = await axios.post(RequestManager.baseURL + url, this, config)

            return plainToClass(to, res.data);
        } catch (error) {
            if (isAxiosError(error) && error.response) {
                if (instanceOfIAPIError(error.response.data))
                    throw new APIError(<any>error.response.data);
            }

            throw error;
        }
    }

    static async delete<T>(this: (new () => T), url: string, config?: AxiosRequestConfig): Promise<any> {
        try {
            return await (await axios.delete(RequestManager.baseURL + url, config)).data
        } catch (error) {
            if (isAxiosError(error) && error.response) {
                if (instanceOfIAPIError(error.response.data))
                    throw new APIError(<any>error.response.data);
            }

            throw error;
        }
    }
}

<template>
  <div class="sidebar" :data-color="backgroundColor" :data-active-color="activeColor">
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in computedSidebarLinks" :key="link.name + index" :link="link">
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            ></sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Session from "@/Session";
import PerfectScrollbar from "perfect-scrollbar";

export default {
  props: {
    title: {
      type: String,
      default: "Easyfito",
      description: "Sidebar title",
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = ["white", "brown", "black"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Sidebar background color (white|brown|black)",
    },
    activeColor: {
      type: String,
      default: "primary",
      validator: (value) => {
        let acceptedValues = ["primary", "info", "success", "warning", "danger"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Sidebar active text color (primary|info|success|warning|danger)",
    },
    logo: {
      type: String,
      default: "static/img/easyfito-logo.png",
      description: "Sidebar Logo",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description: "Sidebar links. Can be also provided as children components (sidebar-item)",
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      computedSidebarLinks: [],
    };
  },
  watch: {
    sidebarLinks: {
      handler(val) {
        val.forEach((link) => {
          if (link.permission == null && link.children == null) this.computedSidebarLinks.push(link);
          else if (
            link.permission != null &&
            link.children == null &&
            Session.instance.userBusiness.permission.canAccess(permission)
          )
            this.computedSidebarLinks.push(link);
          else if (link.permission == null && link.children != null) {
            const newLink = Object.assign({}, link);

            newLink.children = link.children.filter((children) => {
              return Session.instance.userBusiness.permission.canAccess(children.permission);
            });

            if (newLink.children.length > 0) this.computedSidebarLinks.push(newLink);
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    initScrollBarAsync() {
      const isWindows = navigator.platform.startsWith("Win");
      if (!isWindows) {
        return;
      }
      //import PerfectScrollbar from 'perfect-scrollbar';

      // const PerfectScrollbar = await import("perfect-scrollbar");
      new PerfectScrollbar(this.$refs.sidebarScrollArea);
    },
  },
  mounted() {
    this.initScrollBarAsync();
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>

import _ from "lodash";

export default class UserBusinessPermission {
    ID!: string;
    canEditOrder!: boolean;
    canManageAccounts!: boolean;
    canManageStore!: boolean;
    canEditClient!: boolean;
    canReceiveNotifications!: boolean;
    canCreateOrder!: boolean;

    canAccess(requiredAuthorizations: Partial<Pick<UserBusinessPermission, 'canEditOrder' | 'canManageAccounts' | 'canManageStore' | 'canEditClient' | 'canReceiveNotifications' | 'canCreateOrder'>>): boolean {
        return UserBusinessPermission.canAccess(this, requiredAuthorizations);
    }

    static canAccess(userBusinessPermission: UserBusinessPermission, requiredAuthorizations: Partial<Pick<UserBusinessPermission, 'canEditOrder' | 'canManageAccounts' | 'canManageStore' | 'canEditClient' | 'canReceiveNotifications' | 'canCreateOrder'>>): boolean {
        delete userBusinessPermission.ID;

        if (!requiredAuthorizations?.canEditClient && !requiredAuthorizations?.canEditOrder && !requiredAuthorizations?.canManageAccounts && !requiredAuthorizations?.canManageStore && !requiredAuthorizations?.canReceiveNotifications)
            return true;

        const clone = Object.assign({}, requiredAuthorizations); //_.cloneDeep(requiredAuthorizations);

        for (const key in clone) {
            if (!clone[key])
                delete clone[key];
        }

        return _.isMatch(userBusinessPermission, clone);
    }
}

export interface UserBusinessPermissionFields {
    canEditOrder: boolean;
    canManageAccounts: boolean;
    canManageStore: boolean;
    canEditClient: boolean;
    canReceiveNotifications: boolean;
    canCreateOrder: boolean;
}

import { Type } from "class-transformer";
import RequestManager from "../RequestManager";
import UserBusinessPermission, { UserBusinessPermissionFields } from "../UserBusinessPermission/UserBusinessPermission";

interface UserBusinessPermissionGroupPost {
    name: string;
    description?: string;
    permissions: UserBusinessPermissionFields;
}

interface UserBusinessPermissionGroupUpdate {
    name?: string;
    description?: string;
    permissions?: UserBusinessPermissionFields;
}

export default class UserBusinessPermissionGroup extends RequestManager {
    ID!: string;
    name!: string;
    description?: string;
    @Type(() => UserBusinessPermission)
    permission!: UserBusinessPermission;

    static async create(group: UserBusinessPermissionGroupPost): Promise<UserBusinessPermissionGroup> {
        return await Object.assign(new UserBusinessPermissionGroup(), group).postOne('permissiongroups');
    }

    static async remove(ID: string): Promise<void> {
        await UserBusinessPermissionGroup.delete(`permissiongroups/${ID}`);
    }

    static async update(ID: string, group: UserBusinessPermissionGroupUpdate): Promise<void> {
        await Object.assign(new UserBusinessPermissionGroup(), group).patch(`permissiongroups/${ID}`);
    }
}
